<template>
  <div class=container>
      <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td>
              <div align="center"><font size="+2">
                                </font>              </div>
              <br>
              <br>
<h3>Database Organization&nbsp;</h3>
              The release of the database includes 3 parts for each
example: the raw measurement, the fitted BRDF parameters, and the STAF
factorization. Due to the space limitation, we can not release all the
1,280 raw measurements. Instead, we selected 1 view and 6 lighting
directions (thus 6 measurement in total) for each example at each time
step. The structure of the database, for example, tvBTF09, is as
follows:<br>
<br>
<table style="text-align: left; width: 100%;" border="1" cellpadding="2" cellspacing="2">
  <tbody>
    <tr>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);">tvBTF09/</td>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);"></td>
      <td style="font-family: Courier New,Courier,monospace;"></td>
      <td style="font-family: Courier New,Courier,monospace;"></td>
    </tr>
    <tr>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);"></td>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);">btfInfo/</td>
      <td style="font-family: Courier New,Courier,monospace;"></td>
      <td style="font-family: Courier New,Courier,monospace;">3d
        coordinates of the points on the sample</td>
    </tr>
    <tr>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);"></td>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);">raw/</td>
      <td style="font-family: Courier New,Courier,monospace;"></td>
      <td style="font-family: Courier New,Courier,monospace;">6
        measurements at each time step, raw data</td>
    </tr>
    <tr>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);"></td>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);">TSparamNew/</td>
      <td style="font-family: Courier New,Courier,monospace;"></td>
      <td style="font-family: Courier New,Courier,monospace;">fitted
        BRDF parameters</td>
    </tr>
    <tr>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);"></td>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);">SIM/</td>
      <td style="font-family: Courier New,Courier,monospace;"></td>
      <td style="font-family: Courier New,Courier,monospace;">STAF
        model parameters</td>
    </tr>
    <tr>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);"></td>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);">tvBTF09-config.txt</td>
      <td style="font-family: Courier New,Courier,monospace;"></td>
      <td colspan="1" rowspan="3" style="font-family: Courier New,Courier,monospace;">see below</td>
    </tr>
    <tr>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);"></td>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);">tvBTF09-time.txt</td>
      <td style="font-family: Courier New,Courier,monospace;"></td>
    </tr>
    <tr>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);"></td>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);">tvBTF09-info.txt</td>
      <td style="font-family: Courier New,Courier,monospace;"></td>
    </tr>
  </tbody>
</table>
<br>
<br>
<hr style="width: 100%; height: 2px;">
<h3>Name Convention</h3>
There are 26 samples in this database. Each sample has a unique id,
from "tvBTF09" to "tvBTF45" (see <router-link to="/repository/STAF/samples">here</router-link> for a list of the samples). Take tvBTF09 as an example, the name
convention of the database will be given as follows, step by step:<br>
<br>
<ul>
  <li style="color: rgb(0, 0, 153);"><strong>tvBTF09-config.txt</strong></li>
  This file saves some information of the path of the files, the number
  of time steps, the size of the images,
  and so on. It is used to process the data. It is extracted from
  tvBTF09-info.txt.<br>
  <br>
  <li style="color: rgb(0, 0, 153);"><strong>tvBTF09-time.txt</strong></li>
  This file has the time information of each time step when we did the
  measurement. It is extracted from tvBTF09-info.txt<br>
  <br>
  <li style="color: rgb(0, 0, 153);"><strong>tvBTF09-info.txt</strong></li>
  This file describes more information regarding the sample scan:
  <pre>1) Texture Size<br>2) Exposure used<br>3) Spectralon sample used for normalization<br>4) Scan setting as seen from the SphereControl dome program<br>5) Time stamp of each interval<br>
</pre>
  <li style="color: rgb(0, 0, 153);"><strong>btfInfo/</strong></li>
  This folder has the information of the coordinates (2D and 3D) of the
  points on the sample. For example, the resolution of the images in
  tvBTF09 is 512x512. That means there are 512x512 points on the sample.
  The 3D coordinates of these points can be found in the file <font style="color: rgb(0, 0, 153);" color="#0000ff">btfInfo/texturePoints3d.txt</font>,
  and the 2D coordinates of
  these points from each of the 16 views can be found at <font style="color: rgb(0, 0, 153);" color="#0000ff">btfInfo/texturePoints2d_xx.txt</font>,
  where <font style="color: rgb(0, 0, 153);" color="#0000ff">xx</font> is the id of
  the view from 00 to 15.<br>
  <br>
  <li style="color: rgb(0, 0, 153);"><strong>raw/</strong></li>
  This folder has the measurements (raw data) of this sample. As
  mentioned, due to the space limitation, we only provided 6 measurements
  (1 view and 6 lighting directions) at each time step. The name
  convention is as follows:<br>
  <br>
  <center style="color: rgb(0, 0, 153);">
    <strong>norm_btf_LLL_VV_t_TT.exr</strong>
  </center>
  <br>
  where <font style="color: rgb(0, 0, 153);">LLL</font> is the light
  index (from 000 to 149), <font style="color: rgb(0, 0, 153);">VV</font> is the view index (from 00 to 15, see <router-link to="/repository/STAF/documentation">here</router-link> for an explaination of the
  dome), and <font style="color: rgb(0, 0, 153);">TT</font> is the time index (form 00 to the time steps recorded for this sample).
  The image is in the EXR format. This is a format for HDR
  (high dynamic range) image.
  <br>
  The raw data can be used in various ways. For example, in the BRDF
  fitting phase which will be explained right below, we do feel that
  neither the BRDF model we used nor the fitting method is perfect,
  especially for the specular parameters. One could try to use other
  types of BRDF models (e.g. Oren-Nayar + Full Torrance-Sparrow or
  Anisotropic Model), or other more robust fitting algorithm
  with these raw data.<br>
  <br>
  <li style="color: rgb(0, 0, 153);"><strong>TSparamNew/</strong></li>
  This folder has the fitted BRDF parameters at each time step for this
  sample. In our work, the BRDF model we used is defined as follows: <br>
  <br>
  <div style="text-align: center;"><img style="width: 685px; height: 130px;" alt="brdf model" src="https://cave.cs.columbia.edu/old/databases/staf/img/brdf.png"></div>
  <br>
  where there are 5 parameters: 3 for Kd (R,G,B), 1 for Ks, and 1 for
  Sigma. <font color="blue">NOTE: Sigma defined in the above formula is different from the Sigma used in the original paper(Equation 1) for numerical reason. Their relationship is simple: Sigma_defined_here=1/(Sigma_in_paper)^2</font>. So at each time step, we have
  3 EXR images to represent these parameters:
  <ul style="color: rgb(0, 0, 153);">
    <li><strong> tvBTF09-TT-xx-TSparam-Kd.exr </strong></li>
    <li><strong> tvBTF09-TT-xx-TSparam-Ks.exr </strong></li>
    <li><strong> tvBTF09-TT-xx-TSparam-Sigma.exr</strong> </li>
  </ul>
  where <font style="color: rgb(0, 0, 153);">TT</font> is the time
  index. The first one is a color image with R/G/B three channels for Kd.
  The other two are grey level images for Ks and Sigma, respectively.<br>
  <br>
  <li><strong><span style="color: rgb(0, 0, 153);">SIM/</span></strong></li>
  This folder has the STAF model results. The STAF model we proposed is
  defined as follows: <br>
  <br>
  <div style="text-align: center;"><img alt="STAF model" src="https://cave.cs.columbia.edu/old/databases/staf/img/staf.png"><br>
      <div style="text-align: left;"><br>
        In this model, <strong><span style="color: rgb(0, 0, 153);">p(x,y,t)</span></strong> is one of the 5 BRDF parameters we have already fitted, and the
        variables in this model are the following 4 textures and 1 curve:<br>
        <ul>
          <li><strong><span style="color: rgb(0, 0, 153);">A(x,y)</span></strong>:
            the scale factor in the amplitude axis</li>
          <li><strong style="color: rgb(0, 0, 153);">B(x,y)</strong>:
            the scale factor in the time axis</li>
          <li><strong><span style="color: rgb(0, 0, 153);">C(x,y)</span></strong>:
            the offset factor in the time axis</li>
          <li><strong style="color: rgb(0, 0, 153);">D(x,y)</strong>:
            the offset factor in the amplitude axis</li>
          <li><strong><span style="color: rgb(0, 0, 153);">\phi(t)</span></strong>:
            &nbsp;the overall temporal curve</li>
        </ul>
        <br>
        For each of the 5 BRDF parameters, we did the above STAF factorization.
        The results, for example, for the Kd parameters, are saved in the
        following files under this directory:<br>
        <ul>
          <li style="color: rgb(0, 0, 153);"><strong>tvBTF09-staf-Kd-A.exr</strong></li>
          <li style="color: rgb(0, 0, 153);"><strong>tvBTF09-staf-Kd-B.exr</strong></li>
          <li style="color: rgb(0, 0, 153);"><strong>tvBTF09-staf-Kd-C.exr</strong></li>
          <li style="color: rgb(0, 0, 153);"><strong>tvBTF09-staf-Kd-D.exr</strong></li>
          <li style="color: rgb(0, 0, 153);"><strong>tvBTF09-staf-Kd-phi.txt</strong></li>
        </ul>
        The 4 texture images are easy to understand. For the txt file for the
        curve \phi(t), for each curve
        we listed the name of the curve, the points on the curves, the order of
        the polynomial used to fit the curve, the bandwidth of the kernel. Then
        the followed two lines are&nbsp;the values of the points on the
        curves, at the time-axis and the amplitude-axis, respectively. The last
        line is the polynomial fitting
        results. For example, the curve of Kd in the red channel looks like:
        <pre style="background-color: rgb(204, 204, 204);">Red<br>#point 100 #polynomial 7 bopt_r 0.065215<br><br>strav_t_r: -0.121077 -0.108424 -0.095770 ......<br><br>strav_r: 0.807958 0.790653 0.772969 ......<br> <br>pphi_r: 16.695722 -52.611800 58.813562 -26.188925 3.397970 -0.657741 0.635968 </pre>
        We also included a figure to show these curves. For example, the R/G/B
        curves for Kd looks like <a href="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF09_strav.jpg">this</a>.<br>
        <br>
        Our implementation of the algorithm (in Matlab) to estimate the STAF
        model can be downloaded&nbsp;<a href="https://cave.cs.columbia.edu/old/databases/staf/STAF_Release.zip">here</a> (151KB).<br>
        <br>
        Note that there are few samples that can not get robust STAF
        factorization either for the specular BRDF parameters (Ks and Sigma),
        or for all the BRDF parameters The possible reasons are as follows:<br>
        <ul>
          <li>The BRDF fitting for the specular parameters is not
            accurate.</li>
          <li>The specularity is almost zero for these samples.</li>
          <li>There are no obvious time variation in the appearance
            (i.e. the appearance remains almost constant over time).</li>
        </ul>
        <br>
        Below is the list of these samples:
        <ul>
          <li>No STAF for Ks/Sigma: &nbsp;<strong style="color: rgb(0, 0, 153);">tvBTF20</strong>, <strong style="color: rgb(0, 0, 153);">tvBTF28</strong>, <strong style="color: rgb(0, 0, 153);">tvBTF38</strong>, <strong><span style="color: rgb(0, 0, 153);">tvBTF43</span></strong></li>
          <li>No STAF: &nbsp;<strong style="color: rgb(0, 0, 153);">tvBTF34</strong></li>
        </ul>
      </div>
  </div>
</ul>
<hr style="width: 100%; height: 2px;">
<h3><a name="Image_Format"></a>Image Format</h3>
Images in this database are saved in <strong style="color: rgb(0, 0, 153);">EXR</strong> format. EXR can be viewed as a
float-point image type, used to represent HDR (High Dynamic Range)
images. Compared with directly writing float point &nbsp;value to a
raw
file, EXR uses a "Half" type and has an internal compress algorithm to
reduce the size of the image. More information about EXR format and
library to read/write EXR images
in C/C++ (and also a PhotonShop plugin) can be found at <a href="http://www.openexr.com/">OpenEXR</a> website. There is also a set of handy tools for EXR manipulation
available <a href="http://scanline.ca/exrtools/">here</a>.<br>
<br>
In our study, I only used it to write a 3-channel float-point value
image (for grey level images, the values in the 3 channels are the
same), and did not use any fancy features in OpenEXR. I wrote two
simple matlab functions to read/write EXR images for this simple usage.
Download <a href="https://cave.cs.columbia.edu/old/databases/staf/exrio.zip">here</a> (790 KB). Micah Kimo Johnson also wrote
a very nice Matlab tool for read/write EXR images <a href="http://www.mit.edu/~kimo/software/matlabexr/exr.html">here</a>.</td>
          </tr>
          <tr>
            <td><hr style="width: 100%; height: 2px;">
              <router-link to="/repository/STAF">STAF
Database Home</router-link><br>
Contact:&nbsp;<a href="mailto:%20staf@lists.cs.columbia.edu">staf@lists.cs.columbia.edu</a><br>
Last modified: 08/28/2006 </td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>